var _paq = (window._paq = window._paq || []);

function activateMatomoAnalytics(siteId, userDimensions, userInfo) {
  if (!userInfo.enableTracking) {
    return;
  }
  _paq.push(["setCustomDimension", userDimensions.userType, mapUserScope(userInfo.userScope, userInfo.matomoGroup)]);
  _paq.push(["trackPageView"]);
  _paq.push(["enableLinkTracking"]);

  (function () {
    if (!window.Matomo) {
      var u = "https://gridsenergycity.matomo.cloud/";
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", siteId]);
      _paq.push(["requireConsent"]);
      _paq.push(["enableHeartBeatTimer"]);

      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.type = "text/javascript";
      g.async = true;
      g.src = "//cdn.matomo.cloud/gridsenergycity.matomo.cloud/matomo.js";
      s.parentNode.insertBefore(g, s);
    }

    _paq.push(["setConsentGiven"]);
  })();
  // End Matomo Code
}

function deactivateMatomoAnalytics() {
  (function () {
    _paq.push(["forgetConsentGiven"]);
  })();
}

function trackDownload(path) {
  _paq.push(["trackLink", `${window.location.href}/${path}`, "download"]);
}

function trackRouter(url) {
  _paq.push(["setCustomUrl", url]);
  _paq.push(["setDocumentTitle", document.title]);
  _paq.push(["trackPageView"]);
}

function mapUserScope(userScope, matomoGroup) {
  if (matomoGroup) {
    return matomoGroup;
  }

  if (userScope === "endUser") {
    return "External";
  } else if (userScope === "internalStakeholder" || userScope === "externalStakeholder") {
    return "Internal";
  } else {
    // if nothing applies, assume it's internal
    return "Internal";
  }
}

/**
 * Matomo scan forms docs: https://github.com/matomo-org/developer-documentation/blob/7d3a543c6e7574787208c111e851f887dd7b6793/docs/3.x/form-analytics/faq.md
 */
function scanForForms() {
  setTimeout(() => {
    _paq.push(["FormAnalytics::scanForForms"]);
  }, 250);
}

function scanForMedia() {
  setTimeout(() => {
    _paq.push(["MediaAnalytics::scanForMedia"]);
  }, 250);
}

window.activateMatomoAnalytics = activateMatomoAnalytics;
window.deactivateMatomoAnalytics = deactivateMatomoAnalytics;
window.trackDownload = trackDownload;
window.trackRouter = trackRouter;
window.scanForForms = scanForForms;
window.scanForMedia = scanForMedia;
window.mapUserScope = mapUserScope;
